import React from 'react'
import clsx from 'clsx'
import { WidgetProps, schemaRequiresTrueValue } from '@rjsf/utils'
import { FormLabel } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { checkCallback } from '@flint/utils'
import { useStyles } from './YesNoWidget.styles'

interface ICustomYesNoWidgetProps extends WidgetProps {
  labelHide?: boolean
  fullWidth?: boolean
  isActivelyGreen?: (value: any) => any
}

export const CustomYesNoWidget = ({
  schema,
  id,
  options,
  value,
  disabled,
  readonly,
  label,
  onChange,
  onBlur,
  onFocus,
  labelHide,
  fullWidth,
  isActivelyGreen,
  uiSchema,
}: ICustomYesNoWidgetProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const onClickOption = (checked: boolean) => onChange(checked)

  const { enumOptions }: any = options
  const required = schemaRequiresTrueValue(schema)
  const castValue = value === undefined ? false : value

  return (
    <div className={uiSchema['ui:multiLine'] ? classes.root : classes.sameLine}>
      {!labelHide && (label || schema.title) && (
        <FormLabel className={classes.label} required={required} htmlFor={id}>
          {label || schema.title}
        </FormLabel>
      )}

      <div
        className={clsx(classes.optionsContainer, {
          [classes.fullWidth]: fullWidth,
        })}
      >
        {(enumOptions || []).map((option: any, index: number) => (
          <button
            key={index}
            type="button"
            className={clsx(classes.optionButton, {
              [classes.activeOption]: option.value === castValue,
              [classes.activeGreenOption]: checkCallback(isActivelyGreen, [
                option,
              ]),
              [classes.disabledItem]: disabled,
            })}
            disabled={disabled || readonly}
            onClick={() => onClickOption(option.value)}
            onBlur={() => onBlur(id, option.value)}
            onFocus={() => onFocus(id, option.value)}
          >
            {t(option.label)}
          </button>
        ))}
      </div>
    </div>
  )
}
