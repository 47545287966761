import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {},
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f7f7f7',
    marginTop: theme.spacing(3),
    minHeight: 200,
    flexDirection: 'column',
    borderRadius: 10,
    border: 'solid 1px #e0e0e0',
  },
  placeholder: {
    fontSize: 17,
    color: '#aaaaaa',
    marginTop: theme.spacing(2),
    width: '100%',
  },
  input: {
    position: 'absolute',
    left: 0,
    width: '100%',
    top: 0,
    opacity: 0,
    height: '100%',
  },
  hide: {
    display: 'none',
  },
  placeholdetTextStyle: { textAlign: 'center', fontSize: '1.4rem' },
  deleteIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  deleteIcon: { width: '2.2rem', height: '2.2rem', cursor: 'pointer' },
  filesIcon: { marginRight: '1.7rem' },
  addphoto: {
    padding: theme.spacing(2),
    margin: theme.spacing(5, 0),
    borderRadius: theme.spacing(2),
    fontSize: '1.4rem',
  },
  icon: {
    margin: '0 10px',
  },
  hideInput: { display: 'none' },
}))
