/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState } from 'react'
import {
  Button,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  CircularProgress,
} from '@material-ui/core'
import { useTranslation } from '@flint/locales'

import MenuItem from '@material-ui/core/MenuItem'
import AddIcon from '@material-ui/icons/Add'
import { FieldProps } from '@rjsf/utils'
import CloseIcon from '@material-ui/icons/Close'
import { uploadAdvertiseFiles } from 'store/advertiser'
import { useStyles } from './AddPhotoToGallery.style'

interface IPhoto {
  url: string
  type: string
  name: string
}
export const AddPhotoToGallery = (props: FieldProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { formData } = props
  const [files, setFiles] = useState<Array<IPhoto>>(formData || [])
  const [buttonLoading, setButtonLoading] = useState(false)
  const onChangeImage = (e: any) => {
    setButtonLoading(true)
    const currentFile = (e.target || e.dataTransfer).files[0]
    const doesExsists = files.findIndex(
      ({ name }) => name === currentFile?.name
    )

    if (currentFile?.name && doesExsists === -1) {
      uploadAdvertiseFiles(currentFile)
        .then((res: string) => {
          setFiles((prevState) => [
            ...prevState,
            { url: res, type: '', name: currentFile?.name as string },
          ])
          props.onChange(
            files.map((file) => {
              delete file.name
              return file
            })
          )
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setButtonLoading(false)
        })
    }
  }

  const handleSelectChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => {
    files[index].type = event.target.value as string
    setFiles(files)
    props.onChange(
      files.map((file) => {
        delete file.name
        return file
      })
    )
  }

  const handleDeleteImage = (imageName: string) => {
    const currentFiles = [...files]

    const targetIndex = currentFiles.findIndex(({ name }) => name === imageName)
    if (targetIndex >= 0) {
      currentFiles.splice(targetIndex, 1)
    }
    setFiles(currentFiles)
    props.onChange(
      files.map((file) => {
        delete file.name
        return file
      })
    )
  }
  return (
    <div className={classes.root}>
      <div className={classes.uploadedFilesContainer}>
        {files.map(({ url, name }, fileIndex) => (
          <div key={fileIndex} className={classes.uploadedFilePlaceholder}>
            <div key={fileIndex} className={classes.uploadedFileContainer}>
              <img src={url} className={classes.uploadedFile} />
              <IconButton
                className={classes.iconButtomWrapper}
                onClick={() => handleDeleteImage(name)}
              >
                <CloseIcon fontSize="small" className={classes.closeIcon} />
              </IconButton>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-controlled-open-select-label">
                  نوع الصورة
                </InputLabel>
                <Select
                  label="نوع الصورة"
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  className={classes.select}
                  onChange={(e) => handleSelectChange(e, fileIndex)}
                >
                  {
                    // @ts-ignore
                    props.schema.items.properties.type.enum.map(
                      (item: any, index: any) => (
                        <MenuItem
                          key={index}
                          value={item}
                          className={classes.item}
                        >
                          {item}
                        </MenuItem>
                      )
                    )
                  }
                </Select>
              </FormControl>
            </div>
          </div>
        ))}
      </div>
      <div>
        <input
          type="file"
          id="select-image"
          onChange={onChangeImage}
          className={classes.hideInput}
        />
        <label htmlFor={buttonLoading ? '' : 'select-image'}>
          <Button
            variant="contained"
            color="primary"
            component="span"
            fullWidth
            size="large"
            className={classes.addphoto}
          >
            {buttonLoading ? (
              <CircularProgress size={30} thickness={5} color="secondary" />
            ) : (
              <>
                <AddIcon className={classes.icon} />
                <span>{t('add photos')}</span>
              </>
            )}
          </Button>
        </label>
      </div>
    </div>
  )
}
