import React from 'react'
import { WidgetProps } from '@rjsf/utils'
import { SelectWidget } from '@flint/rjsf'
import { useStyle } from './DaalSelectWidgetStyles'

export const DaalSelectWidget = ({ ...props }: WidgetProps) => {
  const classes = useStyle()
  return (
    <div className={classes.root}>
      <h4 className={classes.titleLabel}>{props.label}</h4>
      <div className={classes.widgetContainer}>
        <div className={classes.title}>{props.label}</div>
        <div className={classes.formWrapper}>
          <SelectWidget {...props} oneBorder />
        </div>
      </div>
    </div>
  )
}
