import React, { useState, useEffect, useMemo } from 'react'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import {
  Typography,
  Container,
  useTheme,
  useMediaQuery,
  Box,
  Divider,
  Grid,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { LoadingProgress } from '@flint/core'
import { useTranslation } from '@flint/locales'
import { toast } from 'react-toastify'

import {
  createAdvertise,
  uploadAdvertiseFiles,
  validateAdvertiseLicense,
} from 'store/advertiser'
import { RootState } from 'store/reducer'
import { CustomJsonFormSchema } from 'rjsf/CustomJsonFromScema'
import { VerifyLaterModal } from 'components/modals/verifyLaterModal/VerifyLaterModal'
import { ExclamationIcon } from 'assets'
import { fields } from '../../rjsf/fields'
import { widgets } from '../../rjsf/widgets'
import { useStyles } from './AddPropertyStyles'
import {
  confirmButtonProps,
  resetButtonProps,
} from './AddPropertyActionButtons'
import { StepperComponent } from './AddPropertyStepperComponent'

export const AddPropertePage = (): React.ReactElement => {
  const [formData, setFormData] = useState<any>({})
  const [isVerifyLaterOpen, setIsVerifyLaterOpen] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [licenseNumber, setLicenseNumber] = useState(undefined)
  const [verifyLicenseFeedback, setVerifyLicenseFeedback] = useState({
    text: 'verify data',
    response: '',
  })
  const [adId, setAdId] = useState(null)
  const [isLicenseNumber, setIsLicenseNumber] = useState('yes')
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'))

  const dispatcher = useDispatch()
  const navigate = useNavigate()
  const { advertiser: advertiserReducer } = useSelector(
    (state: RootState) => state
  )
  const {
    addAdvertiseJsonSchema,
    addAdvertiseUiJsonSchema,
    advertiser,
    loader,
  } = advertiserReducer

  const { under_md: underMdUiSchema, up_md: upoveMdUiSchema } =
    addAdvertiseUiJsonSchema

  const uiSchema = isSmallerScreen ? underMdUiSchema : upoveMdUiSchema

  const handleSubmit = () => {
    dispatcher(createAdvertise(formData, navigate, 'INSPECTION', adId))
  }
  useEffect(() => {
    if (!Object.keys(addAdvertiseJsonSchema).length) return

    setShowForm(true)
  }, [addAdvertiseJsonSchema])

  const handleResetButtonClick = () => {
    setIsVerifyLaterOpen(true)
  }

  const checkDuplicates = (errorMessage: string, errors: any[]): boolean => {
    const errorIndex = errors.findIndex((err) => err.message === errorMessage)
    if (errorIndex >= 0) {
      return true
    }
    return false
  }

  const getProperErrorMessage = (
    property: string,
    errors: Array<any>
  ): string => {
    if (
      property.includes(
        '.realestate.realestate_land.borders_area.borders.east'
      ) &&
      !checkDuplicates(t('East border required'), errors)
    ) {
      return t('East border required')
    } else if (
      property.includes(
        '.realestate.realestate_land.borders_area.borders.west'
      ) &&
      !checkDuplicates(t('West border required'), errors)
    ) {
      return t('West border required')
    } else if (
      property.includes(
        '.realestate.realestate_land.borders_area.borders.north'
      ) &&
      !checkDuplicates(t('North border required'), errors)
    ) {
      return t('North border required')
    } else if (
      property.includes(
        '.realestate.realestate_land.borders_area.borders.south'
      ) &&
      !checkDuplicates(t('South border required'), errors)
    ) {
      return t('South border required')
    }
  }

  const transformErrors = (errors: Array<any>) => {
    if (!errors.length) return errors

    toast.error(t('form-errors'))
    return errors.map((error: any) => {
      if (
        error.property.includes('.advertiser.type') ||
        error.property.includes('.advertiser.authorization_number')
      ) {
        error.property = '.advertiser'
      }
      if (
        error.property.includes(
          'realestate.realestate_land.location.coordinates.latitude'
        )
      ) {
        error.property = 'realestate.realestate_land.location.coordinates'
      }
      if (
        error.property.includes(
          '.realestate.realestate_land.borders_area.borders'
        )
      ) {
        error.message = getProperErrorMessage(error.property, errors)
        error.property = '.realestate.realestate_land.borders_area.borders'
      } else if (error.name === 'enum') {
        error.message = t('enum-error-type')
      } else {
        error.message = t('field-is-required')
      }
      return error
    })
  }

  const onFileChange = async (data: Array<{ file: File }> | { file: File }) => {
    try {
      // if multiple files
      if (Array.isArray(data)) {
        const promises = data.map(({ file }) => uploadAdvertiseFiles(file))
        return await Promise.all(promises)
      }
      // if single file
      return uploadAdvertiseFiles(data.file)
    } catch (error) {
      console.error('files upload error: ', error)
    }
  }
  const verifyData = () => {
    dispatcher(
      validateAdvertiseLicense(
        licenseNumber,
        setFormData,
        setVerifyLicenseFeedback,
        setAdId
      )
    )
  }
  const toggleCheckLicenseNumber = (event) => {
    setIsLicenseNumber(event.target.value)
  }
  return (
    <Container className="add-property-container">
      <LoadingProgress loading={loader} />
      <VerifyLaterModal
        isOpen={isVerifyLaterOpen}
        handleClose={() => setIsVerifyLaterOpen(false)}
        handleConfirm={() => {
          // dispatcher(createAdvertise(formData, navigate, 'DRAFT'))
          setFormData({})
          navigate(-1)
        }}
        title={t('Cancel')}
        subTitle={t('Are you sure you want to cancel')}
      />
      <div className={`add-property-responsive ${classes.root}`}>
        <StepperComponent />
        <div className={classes.jsonFromSchemaWrapper}>
          <Typography variant="h1">{t('add new advertise')}</Typography>

          {!showForm ? (
            <form
              onSubmit={(e) => {
                e.preventDefault()
                verifyData()
              }}
              className={classes.licenseContainer}
            >
              <Typography className="title">
                {t('advertising license')}
              </Typography>
              <Divider />
              <Typography className="sub-title">
                {t('is there an advertise license number')}
              </Typography>
              <RadioGroup
                aria-label="is there license number"
                color="primary"
                value={isLicenseNumber}
                onChange={toggleCheckLicenseNumber}
                className={classes.radioButtonContainer}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label={t('true')}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label={t('false')}
                />
              </RadioGroup>
              {isLicenseNumber === 'yes' ? (
                <>
                  <Grid className={classes.advertiserDataContainer} container>
                    <Grid item>
                      <Typography>{t('identity type')}</Typography>
                      <Grid className="info">{t(advertiser?.idType)}</Grid>
                    </Grid>
                    <Grid item>
                      <Typography>{t('identity number')}</Typography>
                      <Grid className="info">{advertiser?.idNumber}</Grid>
                    </Grid>
                  </Grid>
                  <label htmlFor="license number">
                    {t('advertising license number')}
                  </label>
                  <Grid container>
                    <input
                      id="license number"
                      type="number"
                      placeholder={t('license number')}
                      value={licenseNumber}
                      onChange={(e) => {
                        setLicenseNumber(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid className="submitButton">
                    <Button
                      size="small"
                      variant="contained"
                      fullWidth
                      type="submit"
                      disabled={!licenseNumber}
                      className={clsx({
                        [classes.verifyButton]: !verifyLicenseFeedback.response,
                        [classes.successResponse]:
                          verifyLicenseFeedback.response === 'success',
                        [classes.errorResponse]:
                          verifyLicenseFeedback.response === 'error',
                      })}
                    >
                      {t(verifyLicenseFeedback.text)}
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid
                  container
                  alignItems="center"
                  className={classes.footerContainer}
                  wrap="nowrap"
                >
                  <ExclamationIcon />
                  <Typography>
                    {t('according')}
                    &nbsp;{' '}
                    <a
                      href="https://rega.gov.sa/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('rega conditions')}
                    </a>{' '}
                    &nbsp;
                    {t(
                      'advertise license number must be there for publishing the advertise'
                    )}
                  </Typography>
                </Grid>
              )}
            </form>
          ) : (
            <>
              {/* @ts-ignore */}
              <CustomJsonFormSchema
                schema={(addAdvertiseJsonSchema as any) ?? {}}
                uiSchema={uiSchema ?? {}}
                formData={formData}
                fields={fields}
                widgets={widgets}
                setFormData={setFormData}
                onSubmit={handleSubmit}
                actionButtons={{
                  onClickResetButton: handleResetButtonClick,
                  resetButtonHide: false,
                  confirmButtonHide: false,
                  confirmButtonProps,
                  resetButtonProps,
                  confirmButtonLoading: loader,
                }}
                noHtml5Validate
                transformErrors={transformErrors}
                formContext={{ onFileChange }}
                showErrorList={false}
                liveOmit
                omitExtraData
              />
            </>
          )}
        </div>
      </div>
    </Container>
  )
}
