import React, { useEffect, useState } from 'react'
import { ReactJsonSchemaForm } from '@flint/rjsf'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Typography, Container, Divider, Grid } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { createAdvertiser } from 'store/advertiser'
import { RootState } from 'store'
import { ErrorSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'

import { fields } from 'rjsf/fields'
import { widgets } from 'rjsf/widgets'
import JoinAsPersonImg from 'assets/images/joinAsAPerson.png'
import { CardComponent } from 'components/CardComponent/CardComponent'
import {
  SecondStepErrors,
  FormData,
  handleVerify,
} from 'components/BrokerRegisterationsHelpers/seconsStep/SecondStepHelpers'
import { useStyles } from 'components/BrokerRegisterationsHelpers/seconsStep/secondStepStyles'
import { ConfirmationModal } from 'components/modals/ConfirmationModal/ConfirmModal'
import schema from 'tempSchema/daal_join_as_advertiser_schema.json'
import uischema from 'tempSchema/daal_join_as_advertiser_ui_schema.json'
import {
  confirmButtonProps,
  resetButtonProps,
} from '../../components/BrokerRegisterationsHelpers/seconsStep/ActionButtonsProps'

// as agreed with Abdelaoual regarding the latest updates
const staticIdType = 'national'

export const BrokerRegAsPerson = (): React.ReactElement => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [formData, setFormData] = useState<FormData>({})
  const [formErrors, setFormErrors] = useState<SecondStepErrors>({})
  const [isConfirmationButtonOpen, setIsConfirmationButtonOpen] =
    useState(false)
  const [isVerifiedLater, setIsVerifyLater] = useState(false)
  const classes = useStyles()
  const dispatcher = useDispatch()
  const { successMessage } = useSelector((state: RootState) => state.advertiser)

  useEffect(() => {
    if (successMessage.length > 0 && !isVerifiedLater) {
      setIsConfirmationButtonOpen(true)
    } else if (isVerifiedLater) {
      setIsVerifyLater(false)
      handleRedirect()
    }
  }, [successMessage])

  const handleConfirmVerifyLater = () => {
    const formDataChecked = { ...formData }

    setIsVerifyLater(true)
    dispatcher(
      createAdvertiser({
        ...formDataChecked,
        idType: staticIdType,
        validateAdvertiser: false,
      })
    )
  }

  const handleSubmit = () => {
    if (formData?.idNumber) {
      dispatcher(
        createAdvertiser({
          ...formData,
          idType: staticIdType,
          validateAdvertiser: true,
        })
      )
    } else {
      let extraErrors: SecondStepErrors = {}
      const identityNumberRequired = handleVerify({
        inputType: 'idNumber',
        value: formData?.idNumber,
        verification: 'required',
      })
      if (identityNumberRequired?.idNumber) {
        extraErrors = {
          ...extraErrors,
          ...identityNumberRequired,
        }
      }
    }
  }

  const handleRedirect = () => {
    navigate('/add-advertise')
  }

  return (
    <Container className={classes.root}>
      <div className={classes.titleWrapper}>
        <Typography variant="h1" color="textPrimary">
          {t('Join_as_an_advertiser')}
        </Typography>
      </div>
      <div className={classes.secondStepContainer}>
        <ConfirmationModal
          isOpen={isConfirmationButtonOpen}
          handleRedirect={handleRedirect}
          handleClose={() => {
            setIsConfirmationButtonOpen(false)
          }}
        />

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.reverseColumnsWithMobile}
        >
          <Grid item sm={12} md={6}>
            <Typography className={classes.title}>
              {t('advertiser_details_as_in_Real_estate_general_authority')}
            </Typography>
            <ReactJsonSchemaForm
              schema={schema as any}
              uiSchema={uischema}
              extraErrors={formErrors as ErrorSchema<any>}
              formData={formData}
              setFormData={setFormData}
              widgets={widgets}
              fields={fields}
              onChange={(e) => {
                setFormData(e.formData)
                setFormErrors({})
              }}
              onSubmit={handleSubmit}
              actionButtons={{
                resetButtonHide: true,
                confirmButtonHide: false,
                confirmButtonProps,
              }}
              showErrorList={false}
              liveOmit
              validator={validator}
              omitExtraData
            />
          </Grid>
          <Divider
            orientation="vertical"
            className={classes.hideDividerInSmallerScreen}
          />

          <Grid item sm={12} md className={classes.stepWrapper}>
            <CardComponent
              image={JoinAsPersonImg}
              title={t('Join as an individual advertiser')}
              label={t(
                'Adding the advertisement as an independent advertiser without being restricted to an establishment'
              )}
              imgAlt="join as an organization"
              hideLabelOnMobile
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}
