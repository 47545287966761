import { useState, useEffect } from 'react'
import { FieldProps, orderProperties } from '@rjsf/utils'
import { ContentWrapperRoot } from '@flint/rjsf'
import clsx from 'clsx'
import {
  Tooltip,
  TooltipProps,
  Grid,
  Link,
  Typography,
  Divider,
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useStyle, useStylesBootstrap } from './DaalAdverDetailsBasicInfoStyles'

import REalEstateAuth from '../../../assets/images/generalEstateGeneralAuth.svg'

export const DaalAdverDetailsBasicInfo = (props: FieldProps) => {
  const classes = useStyle()
  const {
    title,
    schema,
    readonly,
    disabled,
    formData,
    required,
    registry,
    uiSchema,
    idSchema,
    onChange,
  } = props
  const SchemaField = registry.fields.SchemaField
  const [fields, setFields]: any[] = useState([])
  const onChangeCustomFields = (fieldKey: string, value: any) => {
    onChange({ ...formData, [fieldKey]: value })
  }
  useEffect(() => {
    const initiateComponent = () => {
      let fields: any = schema.properties
      fields = {
        basicInfoFields: fields,
      }
      // if(formData.)
      fields = Object.entries(fields).reduce(
        (fields, [groupKey, groupItems]) => ({
          ...fields,
          [groupKey]: orderProperties(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Object.keys(groupItems) as any,
            (uiSchema['ui:order'] || ['*']) as any
          ).reduce(
            (fields, fieldKey) => ({
              ...fields,
              [fieldKey]: (schema.properties || {})[fieldKey],
            }),
            {}
          ),
        }),
        {}
      )
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      setFields(fields)
    }
    initiateComponent()
  }, [schema, formData])
  function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap()

    return <Tooltip arrow classes={classes} {...props} />
  }

  const getDescription = () =>
    uiSchema['ui:isReelstateAgent'] ? (
      <img src={REalEstateAuth as string} alt="Real estate authority" />
    ) : (
      <BootstrapTooltip
        title={
          <p>
            ببناء على الهيئة العامة للعقار يجب ان يحصل كل إعلان على رقم تفويض من
            المالك لعرض العقار فى إعلان, للحصول على رقم التفويض{' '}
            <span className={classes.clickHere}>اضغط هنا</span>
          </p>
        }
      >
        <Link
          href="https://rega.gov.sa/"
          target="_blank"
          className={classes.regaLink}
        >
          <InfoOutlinedIcon />
          <Typography variant="subtitle2" className={classes.howToGetTheNumber}>
            كيف تحصل على رقم التفويض؟
          </Typography>
        </Link>
      </BootstrapTooltip>
    )

  return (
    <ContentWrapperRoot
      formData={formData}
      uiSchema={uiSchema}
      idSchema={idSchema}
      required={required}
    >
      {Object.entries(fields).map(([groupKey, groupItems]: any) => (
        <div
          key={groupKey}
          className={clsx({
            [classes.makanItemsContainer]: true,
          })}
        >
          <div className={classes.formTitle}>
            <Typography variant="h1" className={classes.titleColor}>
              {schema.title || title}
            </Typography>
            {getDescription()}
          </div>
          <Divider />
          <Grid
            container
            spacing={2}
            className={classes.fieldsContainer}
            style={uiSchema['ui:rootStyle']}
          >
            {Object.entries(groupItems).map(([fieldKey, fieldSchema]: any) => (
              <Grid
                item
                key={fieldKey}
                className={classes.fieldItem}
                xs={(uiSchema[fieldKey] || {})['ui:size'] || 12}
              >
                <SchemaField
                  {...props}
                  schema={fieldSchema}
                  readonly={readonly}
                  disabled={disabled}
                  formData={formData[fieldKey]}
                  uiSchema={uiSchema[fieldKey] || {}}
                  onChange={(value) => onChangeCustomFields(fieldKey, value)}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      ))}
    </ContentWrapperRoot>
  )
}
