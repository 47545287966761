import React, { FC } from 'react'
import { Container, Grid, useMediaQuery, useTheme } from '@material-ui/core'

import { HomeLogo, NewDaalLogo } from 'assets'
import { Search } from './Search'
import { useStyle } from './Home.style'

export const Home: FC = () => {
  const classes = useStyle()
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <div className={classes.root}>
      <Container>
        <Grid
          container
          className={classes.homeContainer}
          justifyContent="center"
          alignItems="center"
        >
          {!isMobileScreen && <NewDaalLogo className={classes.logo} />}

          <Search />
        </Grid>
      </Container>
    </div>
  )
}
