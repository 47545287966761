import { gql } from '@apollo/client'

export const FETCH_USER = gql`
  query FetchUser {
    userDetails {
      email
      firstName
      avatar
      id
      lastName
      phone
      advertiser {
        email
        idNumber
        idType
        phone
      }
    }
  }
`

export const FETCH_ADVERTISES = gql`
  query FetchAdvertise(
    $pageInfo: PageInfoInputType
    $filters: ESLookupsInputType
  ) {
    userAdvertisesList(pageInfo: $pageInfo, filters: $filters) {
      Count
      advertises {
        data
        geometry
        id
        userId
        status
      }
    }
  }
`

export const FETCH_ADVERTISES_COUNT = gql`
  query FetchAdvertise(
    $pageInfo: PageInfoInputType
    $filters: ESLookupsInputType
  ) {
    userAdvertisesList(pageInfo: $pageInfo, filters: $filters) {
      Count
    }
  }
`

export const FETCH_PUBLISHED_ADVERTISES = gql`
  query FetchPublishedAdvertises(
    $pageInfo: PageInfoInputType
    $filters: ESLookupsInputType
  ) {
    publishedAdvertisesList(pageInfo: $pageInfo, filters: $filters) {
      Count
      advertises {
        data
        geometry
        id
        status
      }
    }
  }
`
export const FETCH_ADVERTISE = gql`
  query FetchAdvertise($advertiseId: Int!) {
    advertise(advertiseId: $advertiseId) {
      data
      geometry
      id
      status
      userId
      data
      geometry
    }
  }
`

export const FETCH_ADD_ADVERTISE_SCHEMA = gql`
  query fetchSchema($schemaKey: String!) {
    schema(schemaKey: $schemaKey) {
      jsonSchema
      webUiJsonSchema
    }
  }
`

export const FETCH_ADVERTISER_DETAILS = gql`
  query userDetails($userId: ID!) {
    userDetails(userId: $userId) {
      advertiser {
        id
        idNumber
        idType
        organizationNumber
        phone
        email
      }
      avatar
      email
      firstName
      id
      lastName
      phone
    }
  }
`
export const FETCH_DETAILED_ADVERTISE = gql`
  query detailedAdvertise($advertiseId: Int!) {
    detailedAdvertise(advertiseId: $advertiseId) {
      data {
        data
      }
    }
  }
`
