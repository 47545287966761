import React, { FC, useEffect, useState } from 'react'
import qs from 'qs'
import { LoadingProgress } from '@flint/core'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import {
  Grid,
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducer'
import { fetchUser, resetAdvertiser } from 'store/advertiser'
import { ExpandMenu } from 'components'
import { DaalLogo, NewDaalLogo, Profile } from 'assets'

import { userData, logoutUser } from 'utils/auth'
import { MobileDrawer } from 'components/MobileDrawer/MobileDrawer'
import { useStyle } from './Header.style'
import { RenderAdvertiserTabs } from './RenderAdvertiserTabs'

export const Header: FC = () => {
  const dispatcher = useDispatch()

  const { advertiser } = useSelector((state: RootState) => state)
  const isUserAdvertiser = () =>
    advertiser.advertiser && Object.keys(advertiser.advertiser).length > 0

  useEffect(() => {
    dispatcher(fetchUser())
  }, [])

  const classes = useStyle()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const user = userData()
  const userInfo = advertiser?.userDetails
  const isLoggedIn = user && Object.keys(user).length > 0

  const [loader, setLoader] = useState(false)

  React.useState<null | HTMLElement>(null)

  const handleProfileClick = () => {
    navigate('/profile')
  }

  const handleLogin = () => {
    navigate(
      `/login?${qs.stringify({
        redirectUrl: `${location.pathname}${location.search}`,
      })}`
    )
  }
  const handleLogout = () => {
    setLoader(true)
    logoutUser().then(() => {
      dispatcher(resetAdvertiser('advertiser'))
      navigate('/')
      setLoader(false)
    })
  }

  return (
    <div>
      {isSmallScreen ? (
        <MobileDrawer />
      ) : (
        <header className={`${classes.root} `}>
          <Container>
            <Grid container alignItems="center" justifyContent="center">
              <Grid
                item
                container
                md={6}
                sm={6}
                className={classes.content}
                alignItems="center"
              >
                <NewDaalLogo
                  onClick={() => navigate('/')}
                  className={classes.logo}
                />
                {isUserAdvertiser() && (
                  <Link
                    to="/add-advertise"
                    className={classes.addAdvertiseButton}
                  >
                    <Typography className={classes.title}>
                      {t('add new advertise')}
                    </Typography>
                  </Link>
                )}
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                md={6}
                sm={6}
                className={classes.content}
                justifyContent="flex-end"
              >
                {isLoggedIn ? (
                  <>
                    <Grid
                      item
                      xs={3}
                      md={4}
                      lg={3}
                      container
                      alignItems="center"
                      justifyContent="flex-end"
                      className={classes.userLoggedInDrop}
                    >
                      <RenderAdvertiserTabs />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      md={6}
                      lg={5}
                      container
                      alignItems="center"
                      className={classes.profileTab}
                    >
                      <ExpandMenu
                        isLoginMenu
                        avatar={
                          userInfo.avatar ? (
                            <img
                              alt="avatar"
                              src={userInfo.avatar}
                              className={classes.avatarImage}
                            />
                          ) : (
                            <Grid item>
                              <Profile className={classes.profileIcon} />
                            </Grid>
                          )
                        }
                        title={t('hello')}
                        subheader={`${user.first_name} ${user.last_name}`}
                      >
                        <List>
                          <ListItem
                            button
                            className={classes.listItem}
                            onClick={handleProfileClick}
                          >
                            <ListItemText
                              className={classes.selectOption}
                              disableTypography
                            >
                              {t('profile')}
                            </ListItemText>
                          </ListItem>
                          <ListItem button>
                            <ListItemText
                              className={`${classes.selectOption} ${classes.logoutOption}`}
                              onClick={handleLogout}
                              disableTypography
                            >
                              {t('logout')}
                            </ListItemText>
                          </ListItem>
                        </List>
                      </ExpandMenu>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Link className={classes.clearDecoration} to="/broker">
                      <Typography className={classes.title}>
                        {t('join as advertiser')}
                      </Typography>
                    </Link>
                    <div className={classes.profileContainer}>
                      <Profile className={classes.profileIcon} />
                      <div
                        className={classes.labelsContainer}
                        onClick={handleLogin}
                      >
                        <Typography className={classes.title}>
                          {t('login')}
                        </Typography>
                        <Typography className={classes.subTitle}>
                          {t('or sign up')}{' '}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}
              </Grid>
            </Grid>
          </Container>
          <LoadingProgress loading={loader} />
        </header>
      )}
    </div>
  )
}
