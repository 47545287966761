import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  root: {
    height: 52,
    backgroundColor: '#ffffff',
    borderTop: '1px solid #DFDFDF',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      height: '180px',
    },
  },
  rights: {
    color: '#000000',
  },
  AppStorelogo: {
    cursor: 'pointer',
    '&:first-child': {
      marginInlineEnd: theme.spacing(3),
    },
  },
  title: {
    color: '#000000',
    fontFamily: 'Almarai',
    fontWeight: 400,
    fontSize: '1.2rem',
    cursor: 'pointer',
  },
  decorateText: {
    textDecoration: 'underline',
  },
  wsLogo: {
    borderInline: '1px solid #DFDFDF',
    cursor: 'pointer',
    display: 'inline-block',
    paddingInline: theme.spacing(2),
    marginInline: theme.spacing(3),
    marginTop: 5,
    [theme.breakpoints.down('xs')]: {
      borderInline: 'none',
      width: '100%',
      backgroundColor: '#F0F0F0',
      textAlign: 'center',
      borderRadius: '1rem',
      paddingBlock: '2rem',
      marginBlock: '2rem 3rem',
    },
  },
  logoContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
      marginBlockEnd: theme.spacing(1.5),
    },
  },
  footerImage: {
    borderRight: '2px solid #DFDFDF',
    paddingRight: theme.spacing(1.5),
  },
}))
