import React, { useState, useEffect } from 'react'
import {
  Container,
  Grid,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  AppBar,
  Toolbar,
  Tooltip,
  ClickAwayListener,
  Zoom,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from '@flint/locales'
import { LoadingProgress } from '@flint/core'
import { useParams } from 'react-router-dom'
import { fetchAdvertise, fetchAdvertiserDetails } from 'store/advertiser'

import { RootState } from 'store/reducer'
import { PhoneIcon, MobilePhoneIcon, SharePhoneIcon } from 'assets'
import {
  Gallery,
  MPropertyDetailsStepper,
  PropertyDetailsCard,
  PropertyDetailsStepper,
} from 'components'
import { userData } from 'utils/auth'
import { WarningModal } from 'components/modals/WarningModal'
import { AdvertiserDetailsModal } from 'components/modals/AdvertiserDetailsModal'
import { useStyle } from './PropertyDetails.style'

export const PropertyDetails = () => {
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)
  // is advertise info open
  const [isAdvInfoOpen, setIsAdvInfoOpen] = useState(false)

  const classes = useStyle()
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const dispatcher = useDispatch()
  const currentUser = userData()
  const { t } = useTranslation()
  const { advertiser } = useSelector((state: RootState) => state)
  const { advertiserDetails, loader } = advertiser
  const { selectedAdvertise } = advertiser
  const [open, setOpen] = React.useState(false)

  const isPageReady =
    selectedAdvertise?.data && Object.keys(selectedAdvertise?.data).length > 0
  const { id } = useParams()
  useEffect(() => {
    dispatcher(fetchAdvertise(Number(id)))
  }, [id])

  const handleContactAdveriser = () => {
    if (currentUser?.email && advertiser?.userDetails?.id) {
      dispatcher(fetchAdvertiserDetails(selectedAdvertise?.data?.user_id))
      setIsAdvInfoOpen(true)
    } else {
      setIsWarningModalOpen(true)
    }
  }

  // this should be deleted once B-E is ready _ currently they don't send us the company name _
  const calaTempCompanyName = `${advertiserDetails?.firstName ?? ''} ${
    advertiserDetails?.lastName ?? ''
  }`
  const handleTooltipClose = () => {
    setOpen(false)
  }
  const handleShareClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'منصة دال',
          text: 'اتطلع علي هذا الاعلان',
          url: `${location.href}`,
        })
        .then(() => console.log('Share success'))
        .catch((error) => console.log('Share error:', error))
    } else {
      navigator.clipboard
        .writeText(location.href)
        .then(() => {
          setOpen(true)
        })
        .catch((error) => {
          console.error('Copy to clipboard failed:', error)
        })
    }
  }
  return (
    <div>
      {isPageReady ? (
        <div>
          {isMobileScreen ? (
            <>
              <Grid container direction="column" className={classes.mobileRoot}>
                <Gallery images={selectedAdvertise?.data?.photos} />
                <MPropertyDetailsStepper
                  markerCoords={selectedAdvertise?.geometry?.coordinates}
                  details={selectedAdvertise?.data}
                />
              </Grid>
              <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    className={classes.actionsButtonContainer}
                  >
                    <Grid
                      className={classes.callButton}
                      item
                      xs={5}
                      container
                      alignItems="center"
                      justifyContent="center"
                      onClick={handleContactAdveriser}
                    >
                      <MobilePhoneIcon />
                      <Typography>{t('call')}</Typography>
                    </Grid>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title="advertise link has been copied to your clipboard"
                        classes={{ tooltip: classes.toolTip }}
                        placement="top-end"
                        TransitionComponent={Zoom}
                      >
                        <Grid
                          className={classes.shareButton}
                          item
                          xs={5}
                          container
                          alignItems="center"
                          justifyContent="center"
                          onClick={handleShareClick}
                        >
                          <SharePhoneIcon />
                          <Typography>{t('share with friend')}</Typography>
                        </Grid>
                      </Tooltip>
                    </ClickAwayListener>
                  </Grid>
                </Toolbar>
              </AppBar>
            </>
          ) : (
            <Container>
              <Grid container className={classes.root}>
                <Grid
                  item
                  container
                  md={8}
                  sm={12}
                  xs={12}
                  className={classes.rightContainer}
                  direction="column"
                >
                  <Typography className={classes.pageFooter}>
                    {t('advertise number')} {selectedAdvertise.data.ad_number}
                  </Typography>
                  <PropertyDetailsCard details={selectedAdvertise?.data} />

                  <Gallery images={selectedAdvertise?.data?.photos} />

                  <LoadingProgress loading={loader} />

                  <PropertyDetailsStepper
                    markerCoords={selectedAdvertise?.geometry?.coordinates}
                    details={selectedAdvertise?.data}
                  />

                  {selectedAdvertise.data.qr_code_url && (
                    <>
                      <Typography className={classes.pageFooter}>
                        {t('hyper link')}
                      </Typography>
                      <Typography
                        className={classes.pageFooter}
                        component="a"
                        href={selectedAdvertise.data.qr_code_url}
                        target="_blank"
                      >
                        {selectedAdvertise.data.qr_code_url}
                      </Typography>
                    </>
                  )}

                  <Typography className={classes.pageFooter}>
                    {t('advertise number')} {selectedAdvertise.data.ad_number}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  md={4}
                  sm={12}
                  xs={12}
                  className={classes.leftContainer}
                >
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                  >
                    <Typography variant="h3">{t('advertiser name')}</Typography>
                    <Typography variant="h1" align="center">
                      {selectedAdvertise.data.issuer_full_name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.callButtonContainer}
                  >
                    <Button
                      color="secondary"
                      variant="outlined"
                      startIcon={<PhoneIcon />}
                      fullWidth
                      onClick={handleContactAdveriser}
                    >
                      <Typography variant="h4">
                        {' '}
                        {t('contact advertiser')}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          )}
          <AdvertiserDetailsModal
            isOpen={isAdvInfoOpen && !loader}
            handleClose={() => setIsAdvInfoOpen(false)}
            companyName={calaTempCompanyName}
            avatar={advertiserDetails?.avatar ?? ''}
            phoneNumber={advertiserDetails?.advertiser?.phone ?? ''}
            email={advertiserDetails?.advertiser?.email ?? ''}
          />
          <WarningModal
            isOpen={isWarningModalOpen}
            handleClose={() => setIsWarningModalOpen(false)}
            warningMessage="يرجى تسجيل الدخول او تسجيل عضوية جديدة"
          />
        </div>
      ) : (
        <LoadingProgress loading />
      )}
    </div>
  )
}
