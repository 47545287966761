import { FieldProps } from '@rjsf/utils'
import { realEstateAuth } from 'assets'
import { useStyle } from './RegaRequirementFieldStyles'

export const RegaRequirementField = (props: FieldProps) => {
  const { registry } = props
  const { ObjectField }: any = registry.fields
  const classes = useStyle()

  return (
    <div className={classes.root}>
      <img
        alt="generalEstate"
        src={realEstateAuth}
        className={classes.realEstateAuth}
      />
      <ObjectField {...props} />
    </div>
  )
}
