import { ChangeEvent, useState } from 'react'

import {
  makeStyles,
  Typography,
  CircularProgress,
  Button,
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { TrashIcon, FilesIcon } from 'assets'
import { useTranslation } from '@flint/locales'
import { WidgetProps } from '@rjsf/utils'
import { uploadAdvertiseFiles } from 'store/advertiser'
import { useStyle } from './FileWidget.style'

import { Title } from './Title'

const InputComponent = (props: any) => {
  const { multiple, id, readonly, disabled, options, onChange, className } =
    props

  return (
    <input
      className={className}
      type="file"
      disabled={readonly || disabled}
      id={id}
      multiple={multiple}
      defaultValue=""
      onChange={onChange}
      accept={(options as any).accept}
    />
  )
}
interface IFile {
  url: string

  name: string
}
export const FileWidget = (props: WidgetProps) => {
  const { label, uiSchema = {}, schema, onChange } = props
  const [buttonLoading, setButtonLoading] = useState(false)
  const { t } = useTranslation()
  const classes = useStyle()

  const title = uiSchema['ui:title'] ?? schema.title

  const [files, setFiles] = useState<Array<IFile>>([])

  const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setButtonLoading(true)
    const currentFile = e.target.files[0]
    const doesExists = files.findIndex((i) => i.name === currentFile.name)
    if (currentFile?.name && doesExists === -1) {
      uploadAdvertiseFiles(currentFile)
        .then((res: string) => {
          setFiles((prevState) => [
            ...prevState,
            { url: res, name: currentFile.name as string },
          ])
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setButtonLoading(false)
        })
    }
  }

  const deleteHandler = (index: number) => {
    const currentFiles = [...files]
    currentFiles.splice(index, 1)
    setFiles(currentFiles)
  }
  onChange(files.map(({ url }) => url))
  return (
    <div className={classes.root}>
      <Title title={title} />
      {files.length
        ? files.map((f, index) => (
            <div key={index} className={classes.deleteIconWrapper}>
              <div key={index} className={classes.deleteIconWrapper}>
                <FilesIcon className={classes.filesIcon} />
                <Typography variant="h2">{f.name}</Typography>
              </div>
              <TrashIcon
                onClick={() => deleteHandler(index)}
                color="error"
                className={classes.deleteIcon}
              />
            </div>
          ))
        : null}
      <div>
        <input
          type="file"
          id="select-file"
          onChange={_onChange}
          className={classes.hideInput}
        />
        <label htmlFor="select-file">
          <Button
            variant="contained"
            color="primary"
            component="span"
            fullWidth
            size="large"
            className={classes.addphoto}
          >
            {buttonLoading ? (
              <CircularProgress size={30} thickness={5} color="secondary" />
            ) : (
              <>
                <AddIcon className={classes.icon} />
                <span> {t('attachments')}</span>
              </>
            )}
          </Button>
        </label>
      </div>
    </div>
  )
}
