import React from 'react'
import clsx from 'clsx'
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
} from '@material-ui/core'
import { WidgetProps } from '@rjsf/utils'
import { useStyle } from './RadioComponentsWithTitleStyles'

export const RadioWidgetWithTitle = ({
  id,
  schema,
  options,
  value,
  uiSchema,
  disabled,
  readonly,
  label,
  onChange,
  onBlur,
  onFocus,
}: WidgetProps) => {
  const classes = useStyle()
  const { enumOptions, enumDisabled } = options

  // eslint-disable-next-line no-empty-pattern
  const _onChange = ({}, value: any) =>
    onChange(schema.type === 'boolean' ? value !== 'false' : value)
  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, value)
  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value)

  const row = options ? options.inline : false

  return (
    <div className={clsx(classes.customTextFieldWrapper)}>
      <h4 className={clsx(classes.headerTitle)}>
        {uiSchema['ui:label'] || label || schema.title}
      </h4>

      <RadioGroup
        value={value}
        row={row as boolean}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        className={classes.radioGroup}
      >
        {(enumOptions as any).map((option: any, i: number) => {
          const itemDisabled =
            enumDisabled && (enumDisabled as any).indexOf(option.value) !== -1

          return (
            <FormControlLabel
              control={<Radio color="primary" key={i} />}
              label={
                <Typography className={classes.radioLabel}>
                  {option.label}
                </Typography>
              }
              value={option.value}
              key={i}
              disabled={disabled || itemDisabled || readonly}
              className={clsx(classes.radioItem, {
                selectedRadioItem: option.value === value,
              })}
            />
          )
        })}
      </RadioGroup>
    </div>
  )
}
