import React, { FC, useState } from 'react'
import clsx from 'clsx'
import qs from 'qs'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { Drawer, IconButton, Typography, Grid } from '@material-ui/core'
import { LoadingProgress } from '@flint/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from '@flint/locales'
import { RootState } from 'store/reducer'
import { resetAdvertiser, setAdvertiser } from 'store/advertiser'
import { userData, logoutUser } from 'utils/auth'
import {
  HomeLogo as DrawerLogo,
  MenuIcon,
  WhatsappMobileButton,
  MobileLogo,
  NewDaalLogo,
  MobileFilterIcon,
  MobileSearchIcon,
} from 'assets'

import { useStyle } from './MobileDrawer.style'

export const MobileDrawer: FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [loader, setLoader] = useState(false)
  const classes = useStyle()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatcher = useDispatch()
  const { advertiser } = useSelector((state: RootState) => state)

  const user = userData()

  const isLoggedIn = user && Object.keys(user).length > 0
  // const showAddAdvertiseTab = Boolean(advertiser?.advertiser?.advertiserNumber)

  const toggleDrawer = (value) => (event) => {
    setIsDrawerOpen(value)
  }
  const closeDrawer = () => {
    setIsDrawerOpen(false)
  }
  const handleLogoClick = () => {
    closeDrawer()
    navigate('/')
  }
  const handleLogout = () => {
    setLoader(true)
    closeDrawer()
    logoutUser().then(() => {
      dispatcher(resetAdvertiser('advertiser'))
      navigate('/')
      setLoader(false)
    })
  }

  const handleProfileClick = () => {
    navigate('/profile')
    closeDrawer()
  }
  const handleLogin = () => {
    navigate(
      `/login?${qs.stringify({
        redirectUrl: `${location.pathname}${location.search}`,
      })}`
    )
    closeDrawer()
  }
  const handleClickMyAds = () => {
    navigate('/my-adds')
    closeDrawer()
  }

  const toggleMobileFilter = () => {
    if (location.pathname === '/my-adds') {
      dispatcher(
        setAdvertiser('myAdsMobileFilter', !advertiser.myAdsMobileFilter)
      )
    } else {
      dispatcher(
        setAdvertiser(
          'searchResultsMobileFilter',
          !advertiser.searchResultsMobileFilter
        )
      )
    }
  }
  const toggleMobileSearch = () => {
    dispatcher(
      setAdvertiser(
        'searchResultsMobileSearch',
        !advertiser.searchResultsMobileSearch
      )
    )
  }
  const renderTabs = () => {
    if (!advertiser?.advertiser) {
      return (
        <Link to="/broker" onClick={toggleDrawer(false)}>
          <Typography className={classes.title}>
            {t('join as advertiser')}
          </Typography>
        </Link>
      )
    } else {
      return (
        <Typography className={classes.title} onClick={handleClickMyAds}>
          {t('my ads')} ( {advertiser.advertises.Count} )
        </Typography>
      )
    }
  }
  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems="center"
        className={classes.mobileHeader}
        wrap="nowrap"
      >
        <Grid item>
          <IconButton
            className={classes.menuButton}
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <NewDaalLogo onClick={() => handleLogoClick()} />
        </Grid>
        {location.pathname === '/my-adds' && (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            wrap="nowrap"
          >
            <Grid item>
              <IconButton aria-label="filter" onClick={toggleMobileFilter}>
                <MobileFilterIcon />
              </IconButton>{' '}
            </Grid>
          </Grid>
        )}
        {location.pathname === '/search-results' && (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            wrap="nowrap"
          >
            <Grid item>
              <IconButton aria-label="filter" onClick={toggleMobileFilter}>
                <MobileFilterIcon />
              </IconButton>{' '}
            </Grid>
            <Grid item>
              <IconButton aria-label="search" onClick={toggleMobileSearch}>
                <MobileSearchIcon />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Drawer
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        variant="temporary"
      >
        <div className={classes.drawerContainer}>
          <div className={classes.logoContainer}>
            <NewDaalLogo
              className={classes.logo}
              onClick={() => handleLogoClick()}
            />
          </div>

          <div className={classes.listContainer}>
            <div>
              <Link
                to="/add-advertise"
                className={classes.addAdvertiseButton}
                onClick={toggleDrawer(false)}
              >
                <Typography className={classes.title}>
                  {t('add new advertise')}
                </Typography>
              </Link>
            </div>

            {isLoggedIn ? (
              <>
                <Typography className={classes.title}>
                  {`${user.first_name} ${user.last_name} ${t('hello')} `}
                </Typography>
                {renderTabs()}
                <Typography
                  className={classes.title}
                  onClick={handleProfileClick}
                >
                  {t('profile')}
                </Typography>
              </>
            ) : (
              <>
                <Link to="/broker">
                  <Typography
                    className={classes.title}
                    onClick={toggleDrawer(false)}
                  >
                    {t('join as advertiser')}
                  </Typography>
                </Link>
                <div>
                  <div onClick={handleLogin}>
                    <Typography className={classes.title}>
                      {t('login')} / {t('or sign up')}
                    </Typography>
                  </div>
                </div>
              </>
            )}
          </div>

          {isLoggedIn && (
            <Typography className={classes.logoutOption} onClick={handleLogout}>
              {t('logout')}
            </Typography>
          )}
        </div>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            {' '}
            <Typography
              className={clsx(classes.title, classes.decorateText)}
              onClick={() => navigate('/privacy-policy')}
            >
              {t('privacy policy')}
            </Typography>
          </Grid>
          <Grid item container justifyContent="center">
            {' '}
            <a
              className={classes.wsLogo}
              href="https://api.whatsapp.com/send?phone=966551507434"
              target="_blank"
              rel="noreferrer"
            >
              <WhatsappMobileButton />
            </a>
          </Grid>
          <Grid item>
            {' '}
            <Typography className={classes.title}>{t('copyrights')}</Typography>
          </Grid>
        </Grid>
      </Drawer>
      <LoadingProgress loading={loader} />
    </div>
  )
}
