import React, { FC } from 'react'
import { useTranslation } from '@flint/locales'
import { Grid, Typography } from '@material-ui/core'
import { MDescriptionCard } from './MDescriptionCard'
import { useStyle } from './MPropertyDescriptionCard.style'
import { MPropertyDetailsCard } from '../MPropertyDetailsCard'

export const MPropertyDescriptionCard: FC<any> = ({ details }) => {
  const { t } = useTranslation()
  const classes = useStyle()
  const getCategoryFromDetails = (
    keys: Array<any>,
    isBorders = false,
    path = details
  ) => {
    if (isBorders) {
      return keys.map(({ key, values }) => [
        key,
        ...values.map((value) => details[value]),
      ])
    } else {
      return keys.map((key: string) => ({
        key,
        value: details[key],
      }))
    }
  }
  const documentType = getCategoryFromDetails([
    'ownership_doc_type',
    'deed_number',
    'deed_area',
    'mortgaged',

    'restricted',
  ])

  const deedBordersAndLengths = getCategoryFromDetails(
    [
      {
        key: 'north_border',
        values: [
          'deed_north_border_length',
          'deed_north_border_type',
          'deed_north_border_is_winding',
          'deed_north_border_description',
        ],
      },
      {
        key: 'east_border',
        values: [
          'deed_east_border_length',
          'deed_east_border_type',
          'deed_east_border_is_winding',
          'deed_east_border_description',
        ],
      },
      {
        key: 'south_border',
        values: [
          'deed_south_border_length',
          'deed_south_border_type',
          'deed_south_border_is_winding',
          'deed_south_border_description',
        ],
      },
      {
        key: 'west_border',
        values: [
          'deed_west_border_length',
          'deed_west_border_type',
          'deed_west_border_is_winding',
          'deed_west_border_description',
        ],
      },
    ],
    true
  )

  const landLocation = getCategoryFromDetails(['plan_number', 'parcel_number'])

  const nationalAddress = getCategoryFromDetails([
    'building_number',
    'district',
    'city',
    'zip_code',
    'additional_number',
  ])

  const bordersAndLengths = getCategoryFromDetails(
    [
      {
        key: 'north_border',
        values: [
          'north_border_length',
          'north_border_type',
          'north_border_is_winding',
          'north_border_description',
        ],
      },
      {
        key: 'east_border',
        values: [
          'east_border_length',
          'east_border_type',
          'east_border_is_winding',
          'east_border_description',
        ],
      },
      {
        key: 'south_border',
        values: [
          'south_border_length',
          'south_border_type',
          'south_border_is_winding',
          'south_border_description',
        ],
      },
      {
        key: 'west_border',
        values: [
          'west_border_length',
          'west_border_type',
          'west_border_is_winding',
          'west_border_description',
        ],
      },
    ],
    true
  )
  const realestateServices = getCategoryFromDetails([
    'electricity_services',
    'lighting_services',
    'sewage_services',
    'asphalt_services',
    'water_services',
    'telephone_services',
  ])

  const buildingFacts = getCategoryFromDetails([
    'building_number',
    'construction_year',
    'building_type',
    'finishing_type',
    'is_internet_available',
    'are_there_shared_facilities',
    'is_there_tent',
    'is_there_elevator',
    'is_there_gym',
  ])

  const buildingFactsSub = getCategoryFromDetails([
    'is_there_elevator',
    'is_there_gym',
    'is_there_tent',
  ])

  const propertyFacts = getCategoryFromDetails([
    'unit_number',
    'floor_number',
    'unit_area',
    'number_of_rooms',
    'are_there_views',
    'is_unit_more_than_one_floor',
    'is_there_yard',
    'is_there_mezzanine_floor',
    'is_there_basement',
    'is_there_roof',
    'number_of_private_parking',
  ])

  const landSpecs = getCategoryFromDetails([
    'area',
    'is_walled',
    'land_nature',
    'ground_level',
    'walls_type',
    'walls_length',
  ])
  const services = getCategoryFromDetails([
    'is_electricity_available',
    'is_water_available',
  ])
  const landSpecsAnnexes = getCategoryFromDetails([
    'use',
    'area',
    'structural_framework',
    'building_condition',
  ])

  const landBuildingSystem = getCategoryFromDetails([
    'main_use',
    'sub_use',
    'system',
    'construction_ratio',
  ])
  const specialSpecs = getCategoryFromDetails([
    'is_rented',
    'is_generating_income',
    'total_income',
  ])
  return (
    <div>
      <MPropertyDetailsCard details={details} />
      <MDescriptionCard
        cardTitle="document type"
        details={documentType}
        customStyle={{
          borderBottom: 'none',
        }}
      />
      <MDescriptionCard
        cardTitle="deed borders and lengths"
        details={deedBordersAndLengths}
        customStyle={{
          paddingInline: 0,
        }}
        isBorders
      />
      <Grid className={classes.divider} />
      <MDescriptionCard
        cardTitle="location"
        details={landLocation}
        customStyle={{
          borderBottom: 'none',
        }}
      />

      <MDescriptionCard
        cardTitle="national address"
        details={nationalAddress}
        customStyle={{ paddingBlock: 0 }}
      />
      <Grid className={classes.divider} />
      <MDescriptionCard
        cardTitle="borders and lengths"
        details={bordersAndLengths}
        customStyle={{ paddingInline: 0 }}
        isBorders
      />
      <Grid className={classes.divider} />
      <MDescriptionCard
        cardTitle="available services at real estate"
        details={realestateServices}
      />
      <Grid className={classes.divider} />
      {details.real_estate_type === 'apartment' ? (
        <>
          <MDescriptionCard
            cardTitle="building facts"
            details={buildingFacts}
            customStyle={{ borderBottom: '1px solid #DFDFDF' }}
          />

          <MDescriptionCard
            cardTitle=""
            details={buildingFactsSub}
            customStyle={{
              borderTop: 'none',

              paddingBlock: 0,
            }}
          />
          <Grid className={classes.divider} />
          <MDescriptionCard
            cardTitle="property facts"
            details={propertyFacts}
          />
        </>
      ) : (
        <>
          <MDescriptionCard cardTitle="land specs" details={landSpecs} />
          {details?.annexes?.lenght > 0 && <Grid className={classes.divider} />}

          {details?.annexes?.map((el, i) => (
            <MDescriptionCard
              cardTitle={i === 0 ? 'annexes' : ''}
              details={getCategoryFromDetails(
                ['use', 'area', 'structural_framework', 'building_condition'],
                false,
                details.annexes[i]
              )}
              customStyle={{
                paddingBottom: 0,
              }}
            />
          ))}
          <Grid className={classes.divider} />
          <MDescriptionCard cardTitle="services" details={services} />
          <Grid className={classes.divider} />
          <MDescriptionCard
            cardTitle="land building System"
            details={landBuildingSystem}
          />
        </>
      )}
      <Grid className={classes.divider} />
      <MDescriptionCard cardTitle="special specs" details={specialSpecs} />
      <Grid className={classes.divider} />

      <Grid className={classes.pageFooter}>
        {details.qr_code_url && (
          <>
            <Typography>{t('hyper link')}</Typography>
            <Typography
              component="a"
              href={details.qr_code_url}
              target="_blank"
            >
              {details.qr_code_url}
            </Typography>
          </>
        )}

        <Typography>
          {t('advertise number')} {details.ad_number}
        </Typography>
      </Grid>
    </div>
  )
}
