import React from 'react'
import clsx from 'clsx'
import { schemaRequiresTrueValue } from '@rjsf/utils'
import { useTranslation } from '@flint/locales'
import { checkCallback } from '@flint/utils'

import { IYesNoWidgetProps } from './SideBySideSelector.interface'
import { useStyles } from './SideBySideStyles'

export const SideBySideSelector = ({
  schema,
  id,
  options,
  value,
  disabled,
  readonly,
  label,
  autofocus,
  onChange,
  onBlur,
  onFocus,
  labelHide,
  fullWidth,
  isActivelyGreen,
}: IYesNoWidgetProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const onClickOption = (checked: boolean) => onChange(checked)
  const { enumOptions }: any = options
  const required = schemaRequiresTrueValue(schema)
  const castValue = value === undefined ? false : value
  return (
    <div className={classes.root}>
      {!labelHide && (label || schema.title) && (
        // @ts-ignore
        <h4 className={classes.label} required={required} htmlFor={id}>
          {label || schema.title}
        </h4>
      )}
      <div className={clsx(classes.optionsContainer)}>
        <div className={classes.buttonsWrapper}>
          {(enumOptions || []).map((option: any, index: number) => (
            <button
              key={index}
              type="button"
              className={clsx(classes.optionButton, {
                [classes.activeOption]: option.value === castValue,
                [classes.activeGreenOption]: checkCallback(isActivelyGreen, [
                  option,
                ]),
              })}
              disabled={disabled || readonly}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autofocus && option.value === castValue}
              onClick={() => onClickOption(option.value)}
              onBlur={() => onBlur(id, option.value)}
              onFocus={() => onFocus(id, option.value)}
            >
              {t(option.label)}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}
